import React from "react";
import styled from "styled-components";

const WrapperTool = styled.div`
  margin: 1rem auto;
  text-align:center;
`;
const Paraghraph = styled.strong`
  font-size: 4rem;
`;

const Tools = () => {
  return (
    <WrapperTool>
      <p>
        <Paraghraph>
          I love hearing ideas,
          <br />
          you can count on me <br />
          when you need to react, <br />
          CMS like wordpress <br />
          and design user inteface
          <br />i am with you 😎
        </Paraghraph>
      </p>
    </WrapperTool>
  );
};

export default Tools;
