import React from "react";
import styled from "styled-components";

const Socials = styled.p`
  font-size: 2.5rem;
  text-align: ${props => props.rtl ? "right" : "left"};
`;

const WrappContact = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  @media (max-width: 590px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const Contact = () => {
  return (
    <WrappContact>
      <Socials>
        find me on{" "}
        <a href="https://github.com/alipalvane" target="_blank" rel="noreferrer">
          github,
        </a>
        <br />
        <a href="https://instagram.com/alipalvane" target="_blank" rel="noreferrer">
          instagram,
        </a>{" "}
        <a href="https://www.linkedin.com/in/alipalvane/" target="_blank" rel="noreferrer">
          linkedin,
        </a>
        <br />
        and {" "}
        <a href="https://twitter.com/alipalvane" target="_blank" rel="noreferrer">
           twitter
        </a>
        .
      </Socials>

      <Socials rtl>
        or send me an{" "}
        <a href="mailto:alipalvane@gmail.com" target="_blank" rel="noreferrer">
          email
        </a>
        .
      </Socials>
    </WrappContact>
  );
};

export default Contact;
