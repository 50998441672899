import React from "react";
import styled from "styled-components";
import flower from "../assets/flower.jpg";

const Me = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  @media (max-width: 590px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const Links = styled.h2`
  font-size: 2.5rem;
`;

const Resume = () => {
  return (
    <>
      <Me>
        <div>
          <img src={flower} alt="flower" />
        </div>
        <div>
          <Links>
            my projects are in
            <a href="https://github.com/alipalvane" target="_blank" rel="noreferrer">
              {" "}
              github.
            </a>
          </Links>
          <Links>
            and you can see my little project for fun in
            <a href="https://codepen.io/alipalvane" target="_blank" rel="noreferrer">
              {" "}
              codepen.
            </a>
          </Links>
          <Links>
            for download my CV
            <a href="https://drive.google.com/file/d/190a9MPQmZGaYRy0qAGOjxTWroMcJxi6r/view?usp=share_link" target="_blank" rel="noreferrer">
              {" "}
              here.
            </a>
          </Links>
        </div>
      </Me>
    </>
  );
};

export default Resume;
