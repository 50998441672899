import {Intro, Resume, Tools, Hiro, Contact} from './components'
import styled from "styled-components";
const Wrapper = styled.div`
  width:100%;
  max-width:1800px;
  padding:0 1rem;
  margin:0 auto;
`;
function App() {
  return (
    <Wrapper>
      <Intro/>
      <Resume/>
      <Tools/>
      <Hiro/>
      <Contact/>
    </Wrapper>
  );
}

export default App;
