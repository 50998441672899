import React from "react";
import styled from "styled-components";
import hiroImage from "../assets/hiro-image.jpg";

const WrappHiro = styled.div`
  max-height: 700px;
  overflow: hidden;
  margin-bottom:1rem;
`;
const Hiro = () => {
  return (
    <WrappHiro>
      <img src={hiroImage} alt="hiro image" />
    </WrappHiro>
  );
};

export default Hiro;
