import React from 'react'
import styled from 'styled-components'

const Title = styled.h1`
  font-size: 6rem;
  @media (max-width: 590px) {
    font-size: 3rem;
  }
`;
const Intro = () => {
  return (
    <>
    <Title>👋 i'm ali palvane, a front end developer and ui desginer based in iran.</Title>
    </>
  )
}

export default Intro
